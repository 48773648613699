import $axios from '@/utils/request'

// 获取收费课程
export function getProductList (data) {
  const url = '/product/getProductList'
  return $axios.fPost(url, data)
}

// 新增收费课程
export function addProduct (data) {
  const url = '/product/addProduct'
  return $axios.fPost(url, data)
}

// 编辑收费课程
export function editProduct (data) {
  const url = '/product/editProduct'
  return $axios.fPost(url, data)
}

// 删除收费课程
export function deletProduct (data) {
  const url = '/product/deletProduct'
  return $axios.fGet(url, data)
}

// 新增课程包
export function addProductGroup (data) {
  const url = '/product/group/addProductGroup'
  return $axios.fPost(url, data)
}

// 删除课程包
export function deletProductGroup (data) {
  const url = '/product/group/deletProductGroup'
  return $axios.fGet(url, data)
}

// 修改课程包状态
export function updateStatus (data) {
  const url = '/product/group/updateStatus'
  return $axios.fPost(url, data)
}

// 获取课程包列表
export function getProductGroupList (data) {
  const url = '/product/group/getProductGroupList'
  return $axios.fPost(url, data)
}

// 获取课程根据课程包
export function getProductListByGroup (data) {
  const url = '/product/group/getProductListByGroup'
  return $axios.fGet(url, data)
}

//授权课程包
export function grantCampus (data) {
  const url = '/product/group/grantCampus'
  return $axios.fPost(url, data)
}

//授权课程包
export function ungrantCampus (data) {
  const url = '/product/group/ungrantCampus'
  return $axios.fGet(url, data)
}

//获取某个优惠方案的授权校区
export function getGrantCampus (data) {
  const url = '/product/group/getGrantCampus'
  return $axios.fPost(url, data)
}

//同课不同价授权校区
export function pricegrandCampus (data) {
  const url = '/product/price/grandCampus'
  return $axios.fPost(url, data)
}

//同课不同价取消授权校区
export function priceungrandCampus (data) {
  const url = '/product/price/ungrandCampus'
  return $axios.fGet(url, data)
}
//同课不同价单独新增价格方案
export function addPrice (data) {
  const url = '/product/price/addPrice'
  return $axios.fPost(url, data)
}
//同课不同价单独编辑价格方案
export function editPrice (data) {
  const url = '/product/price/editPrice'
  return $axios.fPost(url, data)
}
//同课不同价单独删除价格方案
export function deletPrice (data) {
  const url = '/product/price/deletPrice'
  return $axios.fGet(url, data)
}

//多科课程授权列表
export function getGrantProduct (data) {
  const url = '/product/group/getGrantProduct'
  return $axios.fGet(url, data)
}

//授权多科课程
export function grantProduct (data) {
  const url = '/product/group/grantProduct'
  return $axios.fPost(url, data)
}

//取消授权多科课程
export function ungrantProduct (data) {
  const url = '/product/group/ungrantProduct'
  return $axios.fGet(url, data)
}

//编辑多科课程折扣
export function editProductGroup (data) {
  const url = '/product/group/editProductGroup'
  return $axios.fPost(url, data)
}
