<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="60%"
    top="5vh"
    @open="openDialog"
    append-to-body
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="diaTitle"></span>
    </div>
    <div>
      <!-- 页面选中数据：{{ multipleSelectionAll }}
      <hr />
      是否多选：{{ isMultiple }}
      <hr />
      已勾选数据：{{ checkData }}
      <hr />
      源数据：{{ originData }}
      <hr />
      数据类型：{{ type }}
      <hr />
      idKey：{{ idKey }} -->
      <el-row :gutter="24">
        <el-col :span="14">
          <el-input
            placeholder="请输入教室名称"
            prefix-icon="el-icon-search"
            @keyup.enter.native="search"
            clearable
            v-model="inputVal"
            v-if="type === 'room'"
          >
          </el-input>
          <el-input
            placeholder="请输入内容"
            v-model="inputVal"
            v-else
            clearable
            class="input-with-select"
            @keyup.enter.native="search"
          >
            <el-select
              v-model="select"
              slot="prepend"
              placeholder="请选择"
              style="width: 100px;"
            >
              <el-option label="用户名" value="1"></el-option>
              <el-option label="姓名" value="2"></el-option>
            </el-select>
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="search"
            ></el-button>
          </el-input>
          <el-row :class="{ isMultiple: !isMultiple }">
            <el-table
              style="margin-top: 15px;"
              :data="tableData"
              :row-key="getRowKeys"
              border
              fit
              highlight-current-row
              @selection-change="handleSelectionChange"
              ref="table"
            >
              <el-table-column
                type="selection"
                width="70"
                align="center"
                :reserve-selection="true"
              ></el-table-column>
              <template v-if="type === 'room'">
                <el-table-column
                  label="教室名称"
                  align="center"
                  prop="title"
                ></el-table-column>
                <el-table-column
                  label="所在校区"
                  align="center"
                  prop="campusTitle"
                ></el-table-column>
                <el-table-column label="容纳人数" align="center">
                  <template slot-scope="scope">
                    <span>{{ scope.row.personCount }}（人）</span>
                  </template>
                </el-table-column>
              </template>
              <template v-else>
                <el-table-column
                  label="用户名"
                  align="center"
                  prop="loginName"
                ></el-table-column>
                <el-table-column
                  label="姓名"
                  align="center"
                  prop="name"
                ></el-table-column>
              </template>
            </el-table>
          </el-row>
          <!-- 分页 -->
          <el-pagination
            background
            class="pagination"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.currentPage"
            :page-sizes="pagination.pageSizes"
            :page-size="pagination.pageSize"
            :layout="pagination.layout"
            :total="pagination.total"
          ></el-pagination>
        </el-col>
        <el-col :span="10">
          <el-row
            style="box-shadow:0px 2px 9px 0px rgba(0, 0, 0, 0.15);padding-bottom: 30px;"
          >
            <el-row class="seleRow">
              <span>已选择：</span>
              <span class="seleNum">{{ multipleSelectionAll.length }}</span>
            </el-row>
            <ul
              v-for="(item, index) of multipleSelectionAll"
              :key="index"
              class="seleList"
            >
              <li class="sele-item">
                <span v-if="type === 'room'">{{ item.title }}</span>
                <span>{{ item.name }}</span>
                <i
                  class="el-icon-delete-solid ico-del"
                  @click="delTeacher(index, item)"
                ></i>
              </li>
            </ul>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <!-- <el-row slot="footer">
      <el-button type="primary" @click="saveRoleForm" size="small"
        >确 定</el-button
      >
      <el-button @click="close" plain size="small">取 消</el-button>
    </el-row> -->

    <el-row slot="footer">
      <el-button type="primary" @click="saveRoleForm" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
  /**
   * @author libin
   * @attribute isMultiple 是否为多选
   * @attribute checkData 已勾选数据
   * @attribute campusId 校区id
   * @attribute type 数据类型（room、teacher、assistant）
   * */

  import { getClassroomList } from '@/api/senate/room'
  import { getUserList } from '@/api/system/staff'

  export default {
    props: {
      show: {
        default: false,
        type: Boolean,
      },
      checkData: {
        default: () => {
          return []
        },
        type: Array,
      },
      type: {
        default: '',
        type: String,
        require: true,
      },
      campusId: {
        default: '',
        type: String,
      },
      isMultiple: {
        default: false,
        type: Boolean,
      },
    },
    watch: {
      checkData: {
        handler(val) {
          // 转换一下目的是为了数据不被同步
          this.multipleSelectionAll = val
        },
        immediate: true,
        deep: true,
      },
    },
    data() {
      return {
        tableData: [],
        pagination: {
          currentPage: 1,
          pageSize: 10,
          layout: 'prev, pager, next',
          total: 0,
        },
        select: '', // 筛选条件 type
        inputVal: '', // 筛选关键词
        idKey: '', // 标识列表数据中每一行的唯一键的名称(需要按自己的数据改一下)
        diaTitle: '',
        multipleSelectionAll: [], // 所有选中的数据包含跨页数据
      }
    },
    methods: {
      getRowKeys(row) {
        return this.type === 'room' ? row.id : row.userId
      },

      // 搜索
      search() {
        this.pagination.currentPage = 1
        this.getData()
      },

      // 窗口打开回调函数
      openDialog() {
        this.inputVal = ''
        this.pagination.currentPage = 1
        this.originData = JSON.parse(JSON.stringify(this.checkData))
        this.multipleSelectionAll = JSON.parse(JSON.stringify(this.checkData))
        this.getData()
      },

      // 获取数据
      getData() {
        switch (this.type) {
          case 'room':
            return (
              (this.idKey = 'id'),
              (this.diaTitle = '选择教室'),
              this.getClassroomList()
            )
          case 'teacher':
            return (
              (this.idKey = 'userId'),
              (this.diaTitle = '选择老师'),
              this.getTeacherList()
            )
          case 'assistant':
            return (
              (this.idKey = 'userId'),
              (this.diaTitle = '选择助教'),
              this.getTeacherList()
            )
          default:
            return false
        }
      },

      // 获取教室列表
      async getClassroomList() {
        const res = await getClassroomList({
          pageNum: this.pagination.currentPage,
          pageSize: this.pagination.pageSize,
          status: '已启用',
          campusId: this.campusId,
          title: this.inputVal,
        })
        this.tableData = res.body.list
        this.pagination.total = res.body.total
        this.toggleRowData()
      },

      toggleRowData() {
        if (this.multipleSelectionAll.length !== 0) {
          let selectAllIds = []
          this.multipleSelectionAll.forEach(row => {
            selectAllIds.push(row[this.idKey])
          })

          for (let i = 0; i < this.tableData.length; i++) {
            if (selectAllIds.indexOf(this.tableData[i][this.idKey]) >= 0) {
              // 设置选中，记住table组件需要使用ref="table"
              this.$refs.table.toggleRowSelection(this.tableData[i], true)
            }
          }
        }
      },

      // 获取（老师 || 助教）列表
      async getTeacherList() {
        const res = await getUserList({
          pageNum: this.pagination.currentPage,
          pageSize: this.pagination.pageSize,
          loginName: this.select === '1' ? this.inputVal : '',
          name: this.select === '2' ? this.inputVal : '',
          state: 1,
          roleCodeSet: [2],
        })
        this.tableData = res.body.list
        this.pagination.total = res.body.total
        this.toggleRowData()
      },

      // 删除数据
      delTeacher(index, item) {
        if (
          !this.multipleSelectionAll ||
          this.multipleSelectionAll.length <= 0
        ) {
          return
        }
        let selectAllIds = this.type === 'room' ? [item.id] : [item.userId]
        this.multipleSelectionAll.splice(index, 1)
        if (this.originData.length !== 0) {
          this.originData.splice(
            this.originData.findIndex(item => item.userId === selectAllIds[0]),
            1
          )
        }
        for (var i = 0; i < this.tableData.length; i++) {
          if (selectAllIds.indexOf(this.tableData[i][this.idKey]) >= 0) {
            // 设置选中，记住table组件需要使用ref="table"
            this.$refs.table.toggleRowSelection(this.tableData[i], false)
          }
        }
      },

      // el-table表格勾选函数
      handleSelectionChange(val) {
        if (this.isMultiple) {
          let obj = {}
          let mergeData = val
            .concat(this.originData.length === 0 ? [] : this.originData)
            .reduce((item, next) => {
              obj[this.type === 'room' ? next.id : next.userId]
                ? ''
                : (obj[this.type === 'room' ? next.id : next.userId] =
                    true && item.push(next))
              return item
            }, [])
          this.multipleSelectionAll = mergeData
        } else {
          this.multipleSelectionAll = val
          if (val.length > 1) {
            this.$refs.table.clearSelection()
            this.$refs.table.toggleRowSelection(val.pop())
          }
        }
      },

      // 关闭
      close() {
        this.$emit('close')
        this.$refs.table.clearSelection()
      },

      // 分页
      handleSizeChange(val) {
        this.pagination.pageSize = val
        this.getData()
      },
      handleCurrentChange(val) {
        this.pagination.currentPage = val
        this.getData()
      },

      // 保存
      saveRoleForm() {
        if (this.multipleSelectionAll.length === 0) {
          window.$msg('请至少选择一条数据', 2)
          return false
        }
        this.$emit('seleContainerData', this.multipleSelectionAll, this.type)
        this.close()
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '../../../style/dialog.scss';
  /deep/ .el-table td {
    padding: 10px 0 !important;
  }
  .seleRow {
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    background: #efefef;
    box-sizing: border-box;
    padding-left: 25px;
  }
  .seleNum {
    color: #fff;
    border-radius: 50%;
    padding: 1px 8px;
    background: #cccccc;
  }
  .seleList {
    box-sizing: border-box;
    margin-top: 20px;
    padding-left: 20px;
  }
  .ico-del {
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
    font-size: 15px;
    &:hover {
      color: #f5c319;
    }
  }
  // 是否取消全选
  .isMultiple {
    /deep/ .el-table__header-wrapper .el-checkbox {
      display: none;
    }
  }
</style>
