<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    top="5vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="dialogTitle"></span>
    </div>
    <!-- schoolArray：{{schoolArray}} -->
    <el-form
      ref="formData"
      :model="formData"
      :rules="rules"
      label-width="120px"
    >
      <el-form-item
        label="所属课程："
        prop="product_id"
        v-if="ordinaryClass && classInfo === null"
      >
        <el-select
          v-model="formData.product_id"
          placeholder="请选择收费课程"
          clearable
          filterable
        >
          <el-option
            v-for="item in ProductList"
            :key="item.id"
            :label="item.productName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="班级名称：" prop="name">
        <el-input
          v-model.trim="formData.name"
          placeholder="请输入班级名称"
          clearable
          @input="$forceUpdate()"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="所属校区："
        prop="campus_id"
      >
        <el-select
          v-model="formData.campus_id"
          placeholder="请选择校区"
          clearable
          filterable
          :disabled="classInfo !== null"
        >
          <el-option
            v-for="item in schoolArray"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开班日期：" prop="open_date">
        <el-date-picker
          v-model="formData.open_date"
          type="date"
          placeholder="选择开班日期"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="班级类型：">
        <el-select
          v-model="formData.type"
          @change="handleTypeChange"
          placeholder="请选择班级类型"
          clearable
          filterable
        >
          <el-option
            v-for="item in typeArray"
            :key="item.value"
            :label="item.value"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-tooltip class="item" effect="dark" content="请先选择班级类型，在选择班容类型" placement="right">
          <i class="el-icon-question" style="font-size: 18px;margin-left: 20px"></i>
        </el-tooltip>
      </el-form-item>
      <el-form-item  v-if="formData.type === '常规班' || formData.type === '竞赛班' || formData.type === '信竞班'" label="班容类型：">
        <el-select
          v-model="formData.lctype"
          placeholder="请选择班容类型"
          clearable
          filterable
          @change="handleLctypeChange"
        >
          <el-option
            v-for="item in lctypeArray"
            :key="item.value"
            :label="item.value"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="默认教室：">
        <el-button
          v-if="classroomList.length === 0"
          type="text"
          icon="el-icon-s-operation"
          :disabled="!formData.campus_id"
          @click="seleContainer('room')"
          >选择教室<span v-if="!classInfo">（请先选择校区）</span></el-button
        >
        <el-select
          v-else
          v-model="formData.classroom_id"
          multiple
          clearable
          @clear="removeTag('room', true)"
          @remove-tag="removeTag('room')"
          @click.native="seleContainer('room')"
        >
          <el-option
            v-for="item in classroomList"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-button
          type="text"
          icon="el-icon-date"
          class="ml-15"
          v-has="'class:roomFreeTime'"
          @click="checkRoomTime"
          >查看教室空闲时间</el-button
        >
      </el-form-item>
      <el-form-item
        label="上课老师："
        prop="teacherIds"
        v-if="classInfo === null"
      >
        <template v-if="classTeacherList.length === 0">
          <el-button
            type="text"
            icon="el-icon-s-operation"
            @click="seleContainer('teacher')"
            >选择老师</el-button
          >
        </template>
        <el-select
          v-model="formData.teacherIds"
          multiple
          v-else
          clearable
          @clear="removeTag('teacher', true)"
          @remove-tag="removeTag('teacher')"
          @click.native="seleContainer('teacher')"
        >
          <el-option
            v-for="item in classTeacherList"
            :key="item.userId"
            :label="item.name"
            :value="item.userId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="上课助教：" v-if="classInfo === null">
        <el-button
          v-if="classAssistantList.length === 0"
          type="text"
          icon="el-icon-s-operation"
          @click="seleContainer('assistant')"
          >选择助教</el-button
        >
        <el-select
          v-model="formData.assistantIds"
          multiple
          v-else
          clearable
          @clear="removeTag('assistant', true)"
          @remove-tag="removeAssistant"
          @click.native="seleContainer('assistant')"
        >
          <el-option
            v-for="item in classAssistantList"
            :key="item.userId"
            :label="item.name"
            :value="item.userId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="授课课程：">
        <el-cascader
          class="w100"
          :disabled="classInfo !== null"
          clearable
          v-model="formData.catalogId"
          :options="tableArr"
          :props="{ checkStrictly: true, value: 'id', label: 'title' }"
        ></el-cascader>
      </el-form-item>
    </el-form>

    <!-- <el-divider></el-divider> -->
    <el-row slot="footer">
      <el-button type="primary" @click="confirm()" size="small" class="dialog-btn"
        >确 定</el-button
      >
      <el-button @click="close()" size="small" type="info" class="dialog-btn">取 消</el-button>
    </el-row>

    <!-- sele-container -->
    <sele-container
      :show="containerDialog"
      :type="containerType"
      :isMultiple="isMultiple"
      :checkData="checkData"
      :campusId="formData.campus_id"
      @close="containerDialog = false"
      @seleContainerData="seleContainerData"
    ></sele-container>

    <!-- 查看教室空闲时间 -->
    <room-time :show="timeDialog" @close="timeDialog = false"></room-time>
  </el-dialog>
</template>

<script>
  import getCourseType from '@/mixins/getCouresTypeLess'
  import SeleContainer from '@/components/senate/sele-container'
  import RoomTime from '@/components/senate/room-time'
  import { getProductList } from '@/api/system/course'

  export default {
    mixins: [getCourseType],
    components: { SeleContainer, RoomTime },
    props: {
      classInfo: {
        default: null,
        type: Object,
      },
      ordinaryClass: {
        default: false,
        type: Boolean,
      },
      show: {
        default: false,
        type: Boolean,
      },
      schoolArray: {
        type: Array,
        require: true,
      },
    },
    watch: {
      show() {
        if (this.show) {
          if (this.$refs.formData) {
            this.$refs.formData.resetFields()
          }
        }
      },
    },
    data() {
      const validTeacher = (rule, value, callback) => {
        if (!value || value.length === 0) {
          return callback(new Error('请选择上课老师'))
        } else {
          callback()
        }
      }
      return {
        dialogTitle: '',
        formData: {
          lctype:''
        },
        classroomList: [], // 教室数据
        classTeacherList: [], // 老师数据
        classAssistantList: [], // 助教数据
        checkData: [],
        ProductList: [], // 收费项目
        isMultiple: false, // 是否多选
        timeDialog: false, // 选择教室 dialog
        rules: {
          name: [
            { required: true, message: '请输入班级名称', trigger: 'blur' },
          ],
          campus_id: [
            { required: true, message: '请选择校区', trigger: 'change' },
          ],
          open_date: [
            { required: true, message: '请选择开班日期', trigger: 'change' },
          ],
          catalogId: [
            { required: true, message: '请选择所属课程', trigger: 'change' },
          ],
          product_id: [
            {
              required: true,
              message: '请选择所属收费课程',
              trigger: 'change',
            },
          ],
          teacherIds: [
            { required: true, validator: validTeacher, trigger: 'blur' },
          ],
        },
        containerDialog: false,
        containerType: '',
        lctypeArray:[
          {
            value:'常规'
          },{
            value:'竞赛'
          },{
            value:'信竞'
          },{
            value:'金牌班'
          },
        ],
        typeArray:[
          {
            value:'常规班'
          },{
            value:'竞赛班'
          },{
            value:'信竞班'
          },{
            value:'活动课'
          },{
            value:'校内趣味社团'
          },{
            value:'校内研习社团'
          },{
            value:'考试测试'
          },{
            value:'企业培训'
          },{
            value:'备课班'
          },{
            value:'其他班级'
          }
        ]
      }
    },
    methods: {
      handleTypeChange() {
        this.lctypeArray = []
        this.formData.lctype = ''
        switch (this.formData.type) {
          case '常规班':
            this.lctypeArray = [
              {
                value:'常规'
              },{
                value:'金牌班'
              },
            ]
            break;
          case '竞赛班':
            this.lctypeArray = [
              {
                value:'竞赛'
              }
            ]
            break;
          case '信竞班':
            this.lctypeArray = [
              {
                value:'信竞'
              }
            ]
            break;
        }
      },
      handleLctypeChange() {
        console.log(this.formData.lctype)
      },
      openDialog() {
        this.formData = {
          lctype:''
        }
        this.classroomList = []
        this.classTeacherList = []
        this.classAssistantList = []
        if (this.ordinaryClass) {
          this.getProductList()
        }
        if (this.classInfo === null) {
          this.dialogTitle = '新增班级'
        } else {
          this.dialogTitle = '编辑班级'
          this.formData = Object.assign({}, this.classInfo)
          this.formData.name = this.classInfo.className
          if (
            this.classInfo.course !== null &&
            this.classInfo.course !== undefined
          ) {
            this.formData.catalogId = this.classInfo.course.id
            // this.hasBindCourse = this.classInfo.course.id
          } else {
            // this.hasBindCourse = ''
          }
          if (this.classInfo.classroomId && this.classInfo.classroomTitle) {
            this.classroomList.push({
              id: this.classInfo.classroomId,
              title: this.classInfo.classroomTitle,
            })
            this.formData.classroom_id = [this.classInfo.classroomId]
          }
        }
      },

      // 获取收费课程
      async getProductList() {
        const res = await getProductList({
          pageNum: 1,
          pageSize: 1000,
          needCount: true,
        })
        this.ProductList = res.body.list
      },

      // 查看教室空闲时间
      checkRoomTime() {
        this.timeDialog = true
      },

      // 选择要加载的数据类型
      seleContainer(type) {
        this.checkData = []
        if (type === 'room') {
          if (this.classroomList.length !== 0) {
            this.checkData = this.classroomList
          }
          this.containerType = 'room'
          this.isMultiple = false
        } else if (type === 'teacher') {
          if (this.classTeacherList.length !== 0) {
            this.checkData = this.classTeacherList
          }
          this.containerType = 'teacher'
          this.isMultiple = false
        } else if (type === 'assistant') {
          if (this.classAssistantList.length !== 0) {
            this.checkData = this.classAssistantList
          }
          this.containerType = 'assistant'
          this.isMultiple = true
        }
        this.containerDialog = true
      },
      // 所选中的数据
      seleContainerData(data, type) {
        if (type === 'room') {
          this.classroomList = [...data]
          this.formData.classroom_id = [data[0].id]
        } else if (type === 'teacher') {
          this.classTeacherList = [...data]
          this.formData.teacherIds = [data[0].userId]
          this.$refs.formData.validateField('teacherIds')
        } else if (type === 'assistant') {
          this.classAssistantList = [...data]
          this.formData.assistantIds = []
          this.classAssistantList.forEach(item => {
            this.formData.assistantIds.push(item.userId)
          })
        }
      },

      // 移除数据函数
      // argument type 类型, empty 是否清空数据
      removeTag(type) {
        if (type === 'room') {
          this.classroomList = []
          this.formData.classroom_id = null
        } else if (type === 'teacher') {
          this.classTeacherList = []
          this.formData.teacherIds = null
        } else if (type === 'assistant') {
          this.classAssistantList = []
          this.formData.assistantIds = null
        }
      },

      removeAssistant(val) {
        this.classAssistantList.forEach((item, index) => {
          if (item.userId === val) {
            this.classAssistantList.splice(index, 1)
          }
        })
      },

      // 选择助教
      seleClassAssistant() {
        this.containerType = 'assistant'
        this.containerDialog = true
      },

      close() {
        this.$emit('close')
      },

      confirm() {
        this.$refs.formData.validate(valid => {
          if (valid) {
            if (this.classInfo === null) {
              this.$emit('addClassForm', this.formData)
            } else {
              this.$emit('editClassForm', this.formData)
            }
            this.close()
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
@import '~@/style/dialog.scss';
</style>
