var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        width: "50%",
        top: "5vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [
          _c("i"),
          _c("span", { domProps: { textContent: _vm._s(_vm.dialogTitle) } }),
        ]
      ),
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _vm.ordinaryClass && _vm.classInfo === null
            ? _c(
                "el-form-item",
                { attrs: { label: "所属课程：", prop: "product_id" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择收费课程",
                        clearable: "",
                        filterable: "",
                      },
                      model: {
                        value: _vm.formData.product_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "product_id", $$v)
                        },
                        expression: "formData.product_id",
                      },
                    },
                    _vm._l(_vm.ProductList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.productName, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "班级名称：", prop: "name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入班级名称", clearable: "" },
                on: {
                  input: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
                model: {
                  value: _vm.formData.name,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.formData,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "formData.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "所属校区：", prop: "campus_id" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择校区",
                    clearable: "",
                    filterable: "",
                    disabled: _vm.classInfo !== null,
                  },
                  model: {
                    value: _vm.formData.campus_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "campus_id", $$v)
                    },
                    expression: "formData.campus_id",
                  },
                },
                _vm._l(_vm.schoolArray, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "开班日期：", prop: "open_date" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  placeholder: "选择开班日期",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.formData.open_date,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "open_date", $$v)
                  },
                  expression: "formData.open_date",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "班级类型：" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择班级类型",
                    clearable: "",
                    filterable: "",
                  },
                  on: { change: _vm.handleTypeChange },
                  model: {
                    value: _vm.formData.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "type", $$v)
                    },
                    expression: "formData.type",
                  },
                },
                _vm._l(_vm.typeArray, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.value, value: item.value },
                  })
                }),
                1
              ),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "请先选择班级类型，在选择班容类型",
                    placement: "right",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-question",
                    staticStyle: { "font-size": "18px", "margin-left": "20px" },
                  }),
                ]
              ),
            ],
            1
          ),
          _vm.formData.type === "常规班" ||
          _vm.formData.type === "竞赛班" ||
          _vm.formData.type === "信竞班"
            ? _c(
                "el-form-item",
                { attrs: { label: "班容类型：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择班容类型",
                        clearable: "",
                        filterable: "",
                      },
                      on: { change: _vm.handleLctypeChange },
                      model: {
                        value: _vm.formData.lctype,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "lctype", $$v)
                        },
                        expression: "formData.lctype",
                      },
                    },
                    _vm._l(_vm.lctypeArray, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.value, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "默认教室：" } },
            [
              _vm.classroomList.length === 0
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "text",
                        icon: "el-icon-s-operation",
                        disabled: !_vm.formData.campus_id,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.seleContainer("room")
                        },
                      },
                    },
                    [
                      _vm._v("选择教室"),
                      !_vm.classInfo
                        ? _c("span", [_vm._v("（请先选择校区）")])
                        : _vm._e(),
                    ]
                  )
                : _c(
                    "el-select",
                    {
                      attrs: { multiple: "", clearable: "" },
                      on: {
                        clear: function ($event) {
                          return _vm.removeTag("room", true)
                        },
                        "remove-tag": function ($event) {
                          return _vm.removeTag("room")
                        },
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.seleContainer("room")
                        },
                      },
                      model: {
                        value: _vm.formData.classroom_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "classroom_id", $$v)
                        },
                        expression: "formData.classroom_id",
                      },
                    },
                    _vm._l(_vm.classroomList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.title, value: item.id },
                      })
                    }),
                    1
                  ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "class:roomFreeTime",
                      expression: "'class:roomFreeTime'",
                    },
                  ],
                  staticClass: "ml-15",
                  attrs: { type: "text", icon: "el-icon-date" },
                  on: { click: _vm.checkRoomTime },
                },
                [_vm._v("查看教室空闲时间")]
              ),
            ],
            1
          ),
          _vm.classInfo === null
            ? _c(
                "el-form-item",
                { attrs: { label: "上课老师：", prop: "teacherIds" } },
                [
                  _vm.classTeacherList.length === 0
                    ? [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              icon: "el-icon-s-operation",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.seleContainer("teacher")
                              },
                            },
                          },
                          [_vm._v("选择老师")]
                        ),
                      ]
                    : _c(
                        "el-select",
                        {
                          attrs: { multiple: "", clearable: "" },
                          on: {
                            clear: function ($event) {
                              return _vm.removeTag("teacher", true)
                            },
                            "remove-tag": function ($event) {
                              return _vm.removeTag("teacher")
                            },
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.seleContainer("teacher")
                            },
                          },
                          model: {
                            value: _vm.formData.teacherIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "teacherIds", $$v)
                            },
                            expression: "formData.teacherIds",
                          },
                        },
                        _vm._l(_vm.classTeacherList, function (item) {
                          return _c("el-option", {
                            key: item.userId,
                            attrs: { label: item.name, value: item.userId },
                          })
                        }),
                        1
                      ),
                ],
                2
              )
            : _vm._e(),
          _vm.classInfo === null
            ? _c(
                "el-form-item",
                { attrs: { label: "上课助教：" } },
                [
                  _vm.classAssistantList.length === 0
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text", icon: "el-icon-s-operation" },
                          on: {
                            click: function ($event) {
                              return _vm.seleContainer("assistant")
                            },
                          },
                        },
                        [_vm._v("选择助教")]
                      )
                    : _c(
                        "el-select",
                        {
                          attrs: { multiple: "", clearable: "" },
                          on: {
                            clear: function ($event) {
                              return _vm.removeTag("assistant", true)
                            },
                            "remove-tag": _vm.removeAssistant,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.seleContainer("assistant")
                            },
                          },
                          model: {
                            value: _vm.formData.assistantIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "assistantIds", $$v)
                            },
                            expression: "formData.assistantIds",
                          },
                        },
                        _vm._l(_vm.classAssistantList, function (item) {
                          return _c("el-option", {
                            key: item.userId,
                            attrs: { label: item.name, value: item.userId },
                          })
                        }),
                        1
                      ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "授课课程：" } },
            [
              _c("el-cascader", {
                staticClass: "w100",
                attrs: {
                  disabled: _vm.classInfo !== null,
                  clearable: "",
                  options: _vm.tableArr,
                  props: { checkStrictly: true, value: "id", label: "title" },
                },
                model: {
                  value: _vm.formData.catalogId,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "catalogId", $$v)
                  },
                  expression: "formData.catalogId",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "primary", size: "small" },
              on: {
                click: function ($event) {
                  return _vm.confirm()
                },
              },
            },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { size: "small", type: "info" },
              on: {
                click: function ($event) {
                  return _vm.close()
                },
              },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
      _c("sele-container", {
        attrs: {
          show: _vm.containerDialog,
          type: _vm.containerType,
          isMultiple: _vm.isMultiple,
          checkData: _vm.checkData,
          campusId: _vm.formData.campus_id,
        },
        on: {
          close: function ($event) {
            _vm.containerDialog = false
          },
          seleContainerData: _vm.seleContainerData,
        },
      }),
      _c("room-time", {
        attrs: { show: _vm.timeDialog },
        on: {
          close: function ($event) {
            _vm.timeDialog = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }